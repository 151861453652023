import logo from './logo.svg';
import './App.css';
import Typewriter from 'typewriter-effect';

function App() {
  // <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  return (
    <>

        <div>:)</div>
        <h1 class="cover">Under Construction</h1>
        <div className='App'>
          <Typewriter 
                    
                    options={{
                        strings: ['Bhavin Ved', 'Photographer', 'NYC Based', 'Please email me@bhavinved.com for inquries'],
                        autoStart: true,
                        loop: true,

                        deleteSpeed: 1,
                    }}
                />
        </div>

    </>
  );
}

export default App;
